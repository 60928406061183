'use client';

import React from 'react';
import Rselect from 'react-select/creatable';
import { Text } from '@/components/Text';
interface SelectProps extends React.HTMLProps<HTMLDivElement> {
  id?: string;
  label?: string;
  defaultValue?: string | number;
  error?: string;
  options: {
    value: string | number;
    label: string;
  }[];
  onUpdate: (_value: string | number) => void;
  onCreateOption?: (_value: string | number) => void;
  isSearchable?: boolean;
  isClearable?: boolean;
  menuIsOpen?: boolean;
  onInputChange?: (_value: string) => void;
  value?: any;
}
export const Select = (props: SelectProps) => {
  const {
    id,
    label,
    defaultValue,
    error,
    options,
    onUpdate,
    className,
    isSearchable = false,
    isClearable = false,
    menuIsOpen,
    onInputChange,
    onCreateOption,
    value
  } = props;
  const idToUse = id || label?.toLowerCase().replace(' ', '-');
  return <div className={className} data-sentry-component="Select" data-sentry-source-file="Select.tsx">
      {label && <Text variant="label" htmlFor={idToUse}>
          {label}
        </Text>}
      <Rselect defaultValue={options.find(option => option.value === defaultValue)} options={options} menuIsOpen={menuIsOpen} isSearchable={isSearchable} isClearable={isClearable} className="mt-1" onInputChange={onInputChange} onCreateOption={onCreateOption} value={value} onChange={option => {
      if (option) {
        onUpdate(option.value);
      }
    }} styles={{
      control: (styles, state) => ({
        ...styles,
        border: state.isFocused ? '1px solid transparent' : '1px solid #3B9167',
        borderRadius: '0.375rem',
        padding: '0.25rem',
        '&:hover': {
          borderColor: '#3B9167'
        },
        fontSize: '1rem'
      }),
      option: (styles, {
        isFocused,
        isSelected
      }) => ({
        ...styles,
        backgroundColor: isSelected ? '#3B9167' : isFocused ? '#3B916720' : 'transparent',
        fontSize: '1rem'
      }),
      indicatorSeparator: styles => ({
        ...styles,
        display: 'none'
      }),
      valueContainer: styles => ({
        ...styles,
        bgcolor: 'red',
        fontSize: '1rem'
      }),
      menu: styles => ({
        ...styles,
        zIndex: 20
      })
    }} data-sentry-element="Rselect" data-sentry-source-file="Select.tsx" />
      {error && <Text fontColor="error">{error}</Text>}
    </div>;
};