'use client';

import { IconCheck, IconEye, IconEyeOff, IconX } from '@tabler/icons-react';
import React, { forwardRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Text } from '@/components/Text';
interface TextInputProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  error?: string;
  labelClass?: string;
  defaultValue?: string;
  value?: string;
  disabled?: boolean;
  withSave?: boolean;
  narrow?: boolean;
  type?: string;
  onUpdate?: (_value: string) => void;
  onChange?: (_event: React.ChangeEvent<HTMLInputElement>) => void;
  onSave?: () => void;
  onCancel?: () => void;
}
export const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const {
    label,
    id,
    className,
    error,
    labelClass,
    defaultValue,
    value,
    disabled,
    withSave,
    narrow,
    type,
    onUpdate,
    onChange,
    onSave,
    onCancel,
    ...rest
  } = props;
  const idToUse = id || label?.toLowerCase().replace(' ', '-');
  const [showPassword, setShowPassword] = useState(false);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate && onUpdate(event.target.value);
    onChange && onChange(event);
  };
  return <div className={twMerge(className, 'flex flex-col ')}>
        {label && <Text variant="label" htmlFor={idToUse} className={labelClass}>
            {label}
          </Text>}
        <div className="relative">
          <input ref={ref} id={label ? idToUse : undefined} className={`form-input ${!narrow ? 'h-[46px]' : 'h-[35px] text-base p-2'} ${withSave ? 'pr-14' : ''} ${error ? 'border-red-500' : ''}`} defaultValue={defaultValue} value={value} disabled={disabled} type={type === 'password' ? showPassword ? 'text' : 'password' : type} {...rest} onChange={handleInputChange} />
          {withSave && <div className="absolute flex right-0 top-0 bottom-0 items-center pr-2 z-0">
              <div className="flex">
                {/* <Button size="sm" onClick={onSave}> */}
                <IconX color="black" onClick={onCancel} />
                <IconCheck color="green" onClick={onSave} />
                {/* </Button> */}
              </div>
            </div>}
          {type === 'password' && <button type="button" onClick={() => {
        setShowPassword(!showPassword);
      }}>
              <div className="absolute flex items-center right-5 top-0 bottom-0">
                {showPassword ? <IconEyeOff size={24} /> : <IconEye size={24} />}
              </div>
            </button>}
        </div>
        {error && <Text fontColor="error">{error}</Text>}
      </div>;
});
TextInput.displayName = 'TextInput';