import { VariantProps, cva } from 'class-variance-authority';
import { HTMLProps } from 'react';
import { twMerge } from 'tailwind-merge';
import { cn } from '@/utils';
interface TextProps extends HTMLProps<HTMLSpanElement>, VariantProps<typeof textVariants> {
  children: React.ReactNode;
}
export const Text = (props: TextProps) => {
  const {
    children,
    className,
    variant,
    fontSize,
    fontColor,
    ...rest
  } = props;
  return <span {...rest} className={twMerge([cn(textVariants({
    variant,
    fontSize,
    fontColor
  })), className])} data-sentry-component="Text" data-sentry-source-file="Text.tsx">
      {children}
    </span>;
};
const textVariants = cva('text-black font-nunito', {
  variants: {
    variant: {
      primary: 'text-md',
      label: 'text-md font-semibold'
    },
    fontSize: {
      xs: 'text-xs',
      sm: 'text-sm',
      md: 'text-base',
      lg: 'text-lg',
      xl: 'text-xl',
      xxl: 'text-2xl'
    },
    fontColor: {
      primary: 'text-black',
      white: 'text-white',
      gray: 'text-gray-500',
      inherit: 'text-inherit',
      error: 'text-red-500'
    }
  },
  defaultVariants: {
    variant: 'primary',
    fontSize: 'md',
    fontColor: 'primary'
  }
});